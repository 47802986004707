import { PropsWithChildren, useEffect, useState } from 'react';

function ClientComponent({ children }: PropsWithChildren) {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return isMounted ? <>{children}</> : <></>;
}

export default ClientComponent;

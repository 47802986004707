import { portalIconsConstraints } from '@/utils/constraints';

export type Portal = {
  id: string;
  name: string;
  description: string;
  order: number;
  banner: string;
  thumbnail: string;
  video: string;
  color: string;
  isOverlayEnabled: boolean;
  contents?: Content[];
  countContents: number;
  iconKey?: keyof typeof portalIconsConstraints;
  hidden?: boolean;
  countImages?: number;
  countVideos?: number;
  countEbooks?: number;
  countQuizzes?: number;
  normalizedCount?: boolean;
};

export type Content = Category | EstablishmentContent | Lesson | EstablishmentGroupContent;
export type CategoryContent = EstablishmentContent | Lesson | EstablishmentGroupContent;

export type CommonContentProps = {
  id: string;
  title: string;
  order: number;
  fullPath: string;
};

export enum ContentTypename {
  CATEGORY = 'CATEGORY',
  ESTABLISHMENT = 'ESTABLISHMENT',
  ESTABLISHMENT_GROUP = 'ESTABLISHMENT_GROUP',
  LESSON = 'LESSON',
}

export type Category = CommonContentProps & {
  _typename: ContentTypename.CATEGORY;
  contents?: CategoryContent[];
  countContents: number;
  description: string;
  banner: string;
  thumbnail: string;
  isOverlayEnabled: boolean;
  colorSource: 'portal' | 'category' | 'custom';
  color?: string;
  hidden?: boolean;
};

export type EstablishmentContent = CommonContentProps & {
  _typename: ContentTypename.ESTABLISHMENT;
  establishmentId: string;
};

export type EstablishmentGroupContent = CommonContentProps & {
  _typename: ContentTypename.ESTABLISHMENT_GROUP;
  establishmentCategoryId?: string;
  regionId?: string;
  orderBy: 'name';
  featured?: boolean;
};

export type Lesson = CommonContentProps & {
  _typename: ContentTypename.LESSON;
  quizzes: Quiz[];
  files?: (File | FileGroup)[];
  banner: string;
  thumbnail: string;
  isOverlayEnabled: boolean;
  videos?: Video[];
  colorSource: 'portal' | 'category' | 'custom';
  color?: string;
  hidden?: boolean;
};

export type Quiz = {
  id: string;
  title: string;
  order: number;
  questions: Question[];
};

export type Question = {
  id: string;
  question: string;
  answers: Answer[];
  rightAnswer: string;
};

export type Answer = {
  id: string;
  answer: string;
};

export type File = {
  id: string;
  name: string;
  description?: string;
  path: string;
  size: string;
  type: string;
  extension: string;
  downloadUrl: string;
  previewUrl?: string;
  callToActionUrl?: string;
};

export type FileGroup = {
  id: string;
  name: string;
  description?: string;
  callToActionUrl?: string;
  files: File[];
};

export type Video = {
  id: string;
  title: string;
  description: string;
  image: string;
  thumbnail: string;
  order: number;
  streamableUrl: string;
  quizId?: string;
  verticalVideo?: boolean;
};

export function isCategory(content: unknown | Category): content is Category {
  return (
    typeof content === 'object' &&
    content !== null &&
    '_typename' in content &&
    content._typename === ContentTypename.CATEGORY
  );
}

export function isEstablishment(
  content: unknown | EstablishmentContent,
): content is EstablishmentContent {
  return (
    typeof content === 'object' &&
    content !== null &&
    '_typename' in content &&
    content._typename === ContentTypename.ESTABLISHMENT
  );
}

export function isEstablishmentGroup(
  content: unknown | EstablishmentGroupContent,
): content is EstablishmentGroupContent {
  return (
    typeof content === 'object' &&
    content !== null &&
    '_typename' in content &&
    content._typename === ContentTypename.ESTABLISHMENT_GROUP
  );
}

export function isLesson(content: unknown | Lesson): content is Lesson {
  return (
    typeof content === 'object' &&
    content !== null &&
    '_typename' in content &&
    content._typename === ContentTypename.LESSON
  );
}

export function isFileGroup(content: unknown | FileGroup): content is FileGroup {
  return (
    typeof content === 'object' &&
    content !== null &&
    'files' in content &&
    Array.isArray(content.files)
  );
}

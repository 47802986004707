import React from 'react';
import { notifications } from '@mantine/notifications';
import { useRollbar } from '@rollbar/react';
import {
  collection,
  doc,
  DocumentReference,
  FirestoreError,
  onSnapshot,
  Query,
  QuerySnapshot,
  SnapshotListenOptions,
  where,
} from 'firebase/firestore';
import {
  useCollectionData as useCollectionDataFirebase,
  useCollectionDataOnce as useCollectionDataOnceFirebase,
  useDocumentData as useDocumentDataFirebase,
  useDocumentDataOnce as useDocumentDataOnceFirebase,
} from 'react-firebase-hooks/firestore';
import {
  IDOptions,
  InitialValueOptions,
  OnceOptions,
  Options,
} from 'react-firebase-hooks/firestore/dist/firestore/types';
import useIsOnline from './useIsOnline';
import {
  createData,
  firestore,
  getCollectionData,
  getCollectionGroupQuery,
  getCompoundQuery,
  getDocumentData,
  getQuery,
  Path,
  softDelete,
  updateData,
} from '@/utils/firebase';
import { useStore } from '@/providers/ZustandProvider';
import { Entity } from '@/stores/slices/FirebaseSlice';
import { ContentTypename } from '@/typings/portal';

type ErrorOptions = {
  errorMessage: string;
  showNotification?: boolean | { error: true };
};

export type DataKey = keyof typeof apiDataConstants;

export type FirestoreData = typeof apiDataConstants;

export type Args<T extends DataKey> = Parameters<FirestoreData[T]['query']>[0];

export const apiDataConstants = {
  users: {
    key: 'users',
    path: ['users'] as Path,
    query: <DataType,>() =>
      getQuery<DataType>({
        path: ['users'],
        order: [{ field: 'name', order: 'asc' }],
      }),
    entity: 'users' as Entity,
  },
  portals: {
    key: 'portals',
    path: ['portals'] as Path,
    query: <DataType,>() =>
      getQuery<DataType>({
        path: ['portals'],
        order: [{ field: 'order', order: 'asc' }],
      }),
    entity: 'portals' as Entity,
  },
  scents: {
    key: 'scents',
    path: ['scents'] as Path,
    query: <DataType,>() =>
      getQuery<DataType>({
        path: ['scents'],
        order: [{ field: 'order', order: 'asc' }],
      }),
    entity: 'scents' as Entity,
  },
  wineColors: {
    key: 'wineColors',
    path: ['wineColors'] as Path,
    query: <DataType,>() =>
      getQuery<DataType>({
        path: ['wineColors'],
        order: [{ field: 'order', order: 'asc' }],
      }),
    entity: 'wineColors' as Entity,
  },
  banners: {
    key: 'banners',
    path: ['banners'] as Path,
    query: <DataType,>() =>
      getQuery<DataType>({
        path: ['banners'],
        order: [{ field: 'order', order: 'asc' }],
      }),
    entity: 'banners' as Entity,
  },
  wineTypes: {
    key: 'wineTypes',
    path: ['wineTypes'] as Path,
    query: <DataType,>() =>
      getQuery<DataType>({
        path: ['wineTypes'],
        order: [{ field: 'order', order: 'asc' }],
      }),
    entity: 'wineTypes' as Entity,
  },
  wineStyles: {
    key: 'wineStyles',
    path: ['wineStyles'] as Path,
    query: <DataType,>() =>
      getQuery<DataType>({
        path: ['wineStyles'],
        order: [{ field: 'order', order: 'asc' }],
      }),
    entity: 'wineStyles' as Entity,
  },
  scentFamilies: {
    key: 'scentFamilies',
    path: ['scentFamilies'] as Path,
    query: <DataType,>() =>
      getQuery<DataType>({
        path: ['scentFamilies'],
        order: [{ field: 'order', order: 'asc' }],
      }),
    entity: 'scentFamilies' as Entity,
  },
  colorCategories: {
    key: 'colorCategories',
    path: ['colorCategories'] as Path,
    query: <DataType,>() =>
      getQuery<DataType>({
        path: ['colorCategories'],
        order: [{ field: 'order', order: 'asc' }],
      }),
    entity: 'colorCategories' as Entity,
  },
  establishmentCategories: {
    key: 'establishmentCategories',
    path: ['establishmentCategories'] as Path,
    query: <DataType,>() =>
      getQuery<DataType>({
        path: ['establishmentCategories'],
        order: [{ field: 'order', order: 'asc' }],
      }),
    entity: 'establishmentCategories' as Entity,
  },
  videoCategories: {
    key: 'videoCategories',
    path: ['videoCategories'] as Path,
    query: <DataType,>() =>
      getQuery<DataType>({
        path: ['videoCategories'],
        order: [{ field: 'order', order: 'asc' }],
      }),
    entity: 'videoCategories' as Entity,
  },
  lessons: {
    key: 'lessons',
    path: ['contents'] as Path,
    query: <DataType,>() =>
      getCollectionGroupQuery<DataType>({
        collectionName: 'contents',
        query: [where('_typename', '==', ContentTypename.LESSON)],
        order: [{ field: 'title', order: 'asc' }],
      }),
    entity: 'lessons' as Entity,
  },
  establishmentsContent: {
    key: 'establishmentsContent',
    path: ['contents'] as Path,
    query: <DataType,>() =>
      getCollectionGroupQuery<DataType>({
        collectionName: 'contents',
        query: [where('_typename', '==', ContentTypename.ESTABLISHMENT)],
        order: [{ field: 'title', order: 'asc' }],
      }),
    entity: 'establishmentsContent' as Entity,
  },
  establishments: {
    key: 'establishments',
    path: ['establishments'] as Path,
    query: <DataType,>() =>
      getQuery<DataType>({
        path: ['establishments'],
        order: [
          // { field: 'featured', order: 'desc' },
          // { field: 'order', order: 'asc' },
          { field: 'name', order: 'asc' },
        ],
      }),
    entity: 'establishments' as Entity,
  },
  categories: {
    key: 'categories',
    path: ['contents'] as Path,
    query: <DataType,>() =>
      getCollectionGroupQuery<DataType>({
        collectionName: 'contents',
        query: [where('_typename', '==', ContentTypename.CATEGORY)],
        order: [{ field: 'title', order: 'asc' }],
      }),
    entity: 'categories' as Entity,
  },
  regions: {
    key: 'regions',
    path: ['regions'] as Path,
    query: <DataType,>() =>
      getQuery<DataType>({
        path: ['regions'],
        order: [{ field: 'order', order: 'asc' }],
      }),
    entity: 'regions' as Entity,
  },
  events: {
    key: 'events',
    path: ['events'] as Path,
    query: <DataType,>() =>
      getQuery<DataType>({
        path: ['events'],
        order: [{ field: 'startDate', order: 'desc' }],
      }),
    entity: 'events' as Entity,
  },
  channelVideos: {
    key: 'channelVideos',
    path: ['channelVideos'] as Path,
    query: <DataType,>() =>
      getQuery<DataType>({
        path: ['channelVideos'],
        order: [{ field: 'order', order: 'asc' }],
      }),
    entity: 'channelVideos' as Entity,
  },
  blogPosts: {
    key: 'blogPosts',
    path: ['blogPosts'] as Path,
    query: <DataType,>() =>
      getQuery<DataType>({
        path: ['blogPosts'],
        order: [{ field: 'publishedAt', order: 'desc' }],
      }),
    entity: 'blogPosts' as Entity,
  },
  tags: {
    key: 'tags',
    path: ['tags'] as Path,
    query: <DataType,>() =>
      getQuery<DataType>({
        path: ['tags'],
        order: [{ field: 'title', order: 'asc' }],
      }),
    entity: 'tags' as Entity,
  },
  confraternities: {
    key: 'confraternities',
    path: ['confraternities'] as Path,
    query: <DataType,>() =>
      getQuery<DataType>({
        path: ['confraternities'],
        order: [{ field: 'name', order: 'asc' }],
      }),
    entity: 'confraternities' as Entity,
  },
  currentMonthWithEvents: {
    key: 'currentMonthWithEvents',
    path: ['monthsWithEvents'] as Path,
    query: <DataType,>({ month, year }: { month: number; year: number }) =>
      getCompoundQuery<DataType>({
        path: ['monthsWithEvents'],
        order: [{ field: 'month', order: 'asc' }],
        query: [where('month', '==', month), where('year', '==', year)],
      }),
    entity: 'currentMonthWithEvents' as Entity,
  },
  monthsWithEvents: {
    key: 'monthsWithEvents',
    path: ['monthsWithEvents'] as Path,
    query: <DataType,>() =>
      getQuery<DataType>({
        path: ['monthsWithEvents'],
        order: [{ field: 'month', order: 'asc' }],
      }),
    entity: 'monthsWithEvents' as Entity,
  },
  smartTVBanners: {
    key: 'smartTVBanners',
    path: ['smartTVBanners'] as Path,
    query: <DataType,>() =>
      getQuery<DataType>({
        path: ['smartTVBanners'],
        order: [{ field: 'order', order: 'asc' }],
      }),
    entity: 'smartTVBanners' as Entity,
  },
  newsletterSubscribers: {
    key: 'newsletterSubscribers',
    path: ['newsletterSubscribers'] as Path,
    query: <DataType,>() =>
      getQuery<DataType>({
        path: ['newsletterSubscribers'],
        order: [{ field: 'createdAt', order: 'asc' }],
      }),
    entity: 'newsletterSubscribers' as Entity,
  },
};

const useFirestoreHooksError = ({
  errorOptions,
  error,
}: {
  errorOptions: ErrorOptions;
  error?: FirestoreError;
}) => {
  const rollbar = useRollbar();

  React.useEffect(() => {
    if (error?.message) {
      rollbar.error(errorOptions?.errorMessage || 'Error in useCollectionData', { error });
      if (
        typeof errorOptions.showNotification === 'boolean'
          ? errorOptions.showNotification
          : errorOptions.showNotification?.error
      ) {
        notifications.show({
          title: 'Ocorreu um erro',
          message: errorOptions?.errorMessage,
          color: 'red',
        });
      }
    }
  }, [error?.message]);
};

// -----------------------------------
// --------- Firestore Hooks ---------
// -----------------------------------
export type ApiArgs<K> = K extends undefined
  ? {
      apiDataKey: DataKey;
      errorOptions: ErrorOptions;
    }
  : {
      apiDataKey: DataKey;
      errorOptions: ErrorOptions;
      queryArgs: K;
    };

export type ApiColArgs<T, K> = ApiArgs<K> & {
  firestoreOptions?: Options & IDOptions<T> & InitialValueOptions<T[]>;
};

export type ApiDocArgs<T, K> = ApiArgs<K> & {
  firestoreOptions?: OnceOptions & IDOptions<T> & InitialValueOptions<T>;
};

export function getFirebaseApiQuery<T, K>(
  api: FirestoreData[DataKey],
  obj: { queryArgs: K } | Record<string, unknown>,
) {
  let query: Query<T>;
  if ('queryArgs' in obj && obj.queryArgs !== undefined) {
    query = (api as { query: (args: K) => Query<T> }).query(obj.queryArgs as K);
  } else {
    query = (api as unknown as { query: () => Query<T> }).query();
  }

  return query;
}

export function getFirebaseApiDocRef<T, K>(
  api: FirestoreData[DataKey],
  obj: { queryArgs: K } | Record<string, unknown>,
) {
  let query: DocumentReference<T>;
  if ('queryArgs' in obj && obj.queryArgs !== undefined) {
    query = (api as unknown as { query: (args: K) => DocumentReference<T> }).query(
      obj.queryArgs as K,
    );
  } else {
    query = (api as unknown as { query: () => DocumentReference<T> }).query();
  }

  return query;
}

export const useCollectionData = <T, K>({
  apiDataKey,
  errorOptions,
  firestoreOptions,
  ...props
}: ApiColArgs<T, K>): ReturnType<typeof useCollectionDataFirebase<T>> => {
  const query = getFirebaseApiQuery<T, K>(apiDataConstants[apiDataKey], props);

  const [data, loading, error, snapshot] = useCollectionDataFirebase<T>(query, firestoreOptions);
  const updateData = useStore((store) => store.updateData);
  React.useEffect(() => {
    if (data) {
      updateData<T[]>(apiDataConstants[apiDataKey].entity, () => data || []);
    }
  }, [data]);

  useFirestoreHooksError({ errorOptions, error });

  return [data, loading, error, snapshot];
};

export const useCollectionDataOnce = <T, K>({
  apiDataKey,
  errorOptions,
  firestoreOptions,
  ...props
}: ApiColArgs<T, K>): ReturnType<typeof useCollectionDataOnceFirebase<T>> => {
  const query = getFirebaseApiQuery<T, K>(apiDataConstants[apiDataKey], props);

  const [data, loading, error, snapshot, reload] = useCollectionDataOnceFirebase<T>(
    query,
    firestoreOptions,
  );

  const updateData = useStore((store) => store.updateData);
  React.useEffect(() => {
    if (data) {
      updateData(apiDataConstants[apiDataKey].entity, () => data || []);
    }
  }, [data]);

  useFirestoreHooksError({ error, errorOptions });

  return [data, loading, error, snapshot, reload];
};

export const useDocumentData = <T, K>({
  apiDataKey,
  errorOptions,
  firestoreOptions,
  ...props
}: ApiDocArgs<T, K>): ReturnType<typeof useDocumentDataFirebase<T>> => {
  const ref = getFirebaseApiDocRef<T, K>(apiDataConstants[apiDataKey], props);

  const [data, loading, error, snapshot] = useDocumentDataFirebase<T>(ref, firestoreOptions);

  useFirestoreHooksError({ error, errorOptions });

  return [data, loading, error, snapshot];
};

export const useDocumentDataOnce = <T, K>({
  apiDataKey,
  errorOptions,
  firestoreOptions,
  ...props
}: ApiDocArgs<T, K>): ReturnType<typeof useDocumentDataOnceFirebase<T>> => {
  const ref = getFirebaseApiDocRef<T, K>(apiDataConstants[apiDataKey], props);

  const [data, loading, error, snapshot, reload] = useDocumentDataOnceFirebase<T>(
    ref,
    firestoreOptions,
  );

  useFirestoreHooksError({ error, errorOptions });

  return [data, loading, error, snapshot, reload];
};

type ApiSnapshotArgs<T, K> = ApiArgs<K> & {
  options: SnapshotListenOptions;
  onNext?: (snapshot: QuerySnapshot<T>) => void;
  onError?: (error: FirestoreError) => void;
  trigger?: string | boolean;
};

export const useSnapshot = <DataType, QueryArgs>({
  apiDataKey,
  options,
  onNext,
  onError,
  trigger,
  errorOptions,
  ...props
}: ApiSnapshotArgs<DataType, QueryArgs>) => {
  const [storeData, updateData] = useStore((store) => [
    store[apiDataConstants[apiDataKey].entity],
    store.updateData,
  ]);
  const rollbar = useRollbar();

  React.useEffect(() => {
    if (!trigger) return;

    const query = getFirebaseApiQuery<DataType, QueryArgs>(apiDataConstants[apiDataKey], props);

    return onSnapshot<DataType>(
      query,
      options,
      (snapshot) => {
        if (snapshot.docs?.length) {
          updateData(apiDataConstants[apiDataKey].entity, () =>
            snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })),
          );
        } else {
          updateData(apiDataConstants[apiDataKey].entity, () => []);
        }
        onNext?.(snapshot);
      },
      (error) => {
        rollbar.log(errorOptions.errorMessage, { error });
        if (errorOptions.showNotification) {
          notifications.show({
            title: 'Ocorreu um erro',
            message: errorOptions.errorMessage,
            color: 'red',
          });
        }
        onError?.(error);
      },
    );
  }, [trigger]);

  return [storeData] as unknown as DataType[];
};

export function checkShowNotification(
  show: boolean | { success?: boolean; error?: boolean } | undefined,
) {
  if (typeof show === 'boolean') return show;
  return show?.success;
}

// ----------------------------------------
// --------- Firestore "Rest" API ---------
// ----------------------------------------
export type ExtraOptions = {
  errorMessage: string;
  onSuccess?: (id: string) => void;
  onError?: () => void;
} & (
  | { successMessage: string; showNotification: true | { success: true; error?: boolean } }
  | { successMessage?: undefined; showNotification?: false | { error?: boolean; success: false } }
);

export const useFirestoreApi = <DataType extends { id: string }>(apiDataKey: DataKey) => {
  const entity = apiDataConstants[apiDataKey].entity;

  const isOnline = useIsOnline();
  const [storeData, updateStoreData] = useStore((store) => [
    store[entity] as unknown as DataType[] | null,
    store.updateData,
  ]);

  const handleCreate = React.useCallback(
    async (
      path: Path,
      data: DataType,
      extraOptions: ExtraOptions,
      setLoading: (value: boolean) => void,
    ) => {
      setLoading(true);
      let firestoreId: string | null = null;
      if (isOnline) {
        const res = await createData(path, data);
        firestoreId = res.id;
      } else {
        const collectionRef = collection(firestore, ...path);
        const docRef = doc(collectionRef);
        firestoreId = docRef.id;
        // On offline mode we don't wait for the response, so we generate the id manually
        createData([...path, firestoreId], data);
      }
      updateStoreData<DataType[]>(apiDataConstants[apiDataKey].entity, (state) => [
        ...state,
        ...(state.some((d) => d.id === firestoreId) ? [] : [{ ...data, id: firestoreId }]),
      ]);
      /*       updateStoreData([...(storeData || []), { ...data, id: firestoreId }], entity); */
      extraOptions.onSuccess?.(firestoreId);
      setLoading(false);
      if (checkShowNotification(extraOptions.showNotification)) {
        notifications.show({
          title: 'Operação realizada com sucesso',
          message: extraOptions.successMessage,
          color: 'green',
        });
      }
    },
    [isOnline, storeData],
  );

  const handleUpdate = React.useCallback(
    async (
      path: Path,
      id: string,
      data: DataType,
      extraOptions: ExtraOptions & { merge?: boolean },
      setLoading: (value: boolean) => void,
    ) => {
      setLoading(true);
      if (isOnline) {
        await updateData(path, id, data, {
          merge: extraOptions.merge === undefined ? true : extraOptions.merge,
        });
      } else {
        // On offline mode we don't wait for the response, so we generate the id manually
        updateData(path, id, data, {
          merge: extraOptions.merge === undefined ? true : extraOptions.merge,
        });
      }
      updateStoreData<DataType[]>(entity, (state) => [
        ...(state || []).map((d) => (d.id === id ? { ...d, ...data } : d)),
      ]);
      extraOptions.onSuccess?.(id);
      setLoading(false);
      if (checkShowNotification(extraOptions.showNotification)) {
        notifications.show({
          title: 'Operação realizada com sucesso',
          message: extraOptions.successMessage,
          color: 'green',
        });
      }
    },
    [isOnline, storeData],
  );

  const handleDelete = React.useCallback(
    async (
      path: Path,
      id: string,
      extraOptions: ExtraOptions,
      setLoading: (value: boolean) => void,
    ) => {
      setLoading(true);
      if (isOnline) {
        await softDelete(path, id);
      } else {
        softDelete(path, id);
      }
      updateStoreData<DataType[]>(entity, (state) => [...(state || []).filter((d) => d.id !== id)]);
      extraOptions.onSuccess?.(id);
      setLoading(false);
      if (checkShowNotification(extraOptions.showNotification)) {
        notifications.show({
          title: 'Operação realizada com sucesso',
          message: extraOptions.successMessage,
          color: 'green',
        });
      }
    },
    [isOnline, storeData],
  );

  const handleGetCollection = React.useCallback(
    async (query: Query<DataType>) => {
      const data = await getCollectionData(query);
      return data;
    },
    [isOnline],
  );

  const handleGetDocument = React.useCallback(
    async (path: Path) => {
      const data = await getDocumentData<DataType>(path);
      return data;
    },
    [isOnline],
  );

  return { handleCreate, handleUpdate, handleDelete, handleGetCollection, handleGetDocument };
};

import { useStore } from '@/providers/ZustandProvider';
import { notifications } from '@mantine/notifications';
import { useRollbar } from '@rollbar/react';
import { useCallback, useState } from 'react';
import {
  ApiColArgs,
  apiDataConstants,
  ApiDocArgs,
  DataKey,
  ExtraOptions as Options,
  getFirebaseApiQuery,
  useCollectionData,
  useCollectionDataOnce,
  useDocumentData,
  useDocumentDataOnce,
  useFirestoreApi,
} from './useFirestoreApi';

export const useApi = <DataType extends { id: string }, ArgsType>(apiDataKey: DataKey) => {
  const { handleCreate, handleUpdate, handleDelete, handleGetCollection, handleGetDocument } =
    useFirestoreApi<DataType>(apiDataKey);
  const [loading, setLoading] = useState(false);

  const entity = apiDataConstants[apiDataKey].entity;

  const storeData = useStore((store) => store[entity] as unknown as DataType[] | null);
  const rollbar = useRollbar();

  const handleError = useCallback((error: Error, options: Options) => {
    rollbar.log(options.errorMessage, { error });
    if (
      typeof options.showNotification === 'boolean'
        ? options.showNotification
        : options.showNotification?.error
    ) {
      notifications.show({
        title: 'Ocorreu um erro',
        message: options.errorMessage,
        color: 'red',
      });
    }
    options.onError?.();
    setLoading(false);
  }, []);

  /**
   * Create new data
   * @param data
   * @param options
   */
  const create = (data: DataType, options: Options) => {
    try {
      handleCreate(apiDataConstants[apiDataKey].path, data, options, (value) => setLoading(value));
    } catch (error) {
      handleError(error as Error, options);
    }
  };

  /**
   * Update data
   * @param id
   * @param data
   * @param options
   */
  const update = (id: string, data: DataType, options: Options) => {
    try {
      handleUpdate(apiDataConstants[apiDataKey].path, id, data, options, (value) =>
        setLoading(value),
      );
    } catch (error) {
      handleError(error as Error, options);
    }
  };

  /**
   * Delete data
   * @param {string} id
   * @param {Options} options
   */
  const deleteData = (id: string, options: Options) => {
    try {
      handleDelete(apiDataConstants[apiDataKey].path, id, options, (value) => setLoading(value));
    } catch (error) {
      handleError(error as Error, options);
    }
  };

  /**
   * Get single data only from the API
   * @param id
   * @param options
   * @returns data with corresponding ID
   */
  const getSingleData = async (id: string, options: Omit<Options, 'onSuccess'>) => {
    try {
      setLoading(true);
      const res = await handleGetDocument([...apiDataConstants[apiDataKey].path, id]);
      setLoading(false);
      return res;
    } catch (error) {
      handleError(error as Error, options as Options);
    }
  };

  /**
   * Get single data only from the API
   * @param queryId
   * @param options
   * @returns list of data based on the queryId and apiDataKey provided on the useApi call
   */
  const getListData = async (args: ArgsType, options: Omit<Options, 'onSuccess'>) => {
    try {
      setLoading(true);
      const query = getFirebaseApiQuery<DataType, ArgsType>(apiDataConstants[apiDataKey], {
        queryArgs: args,
      });
      const res = await handleGetCollection(query);
      setLoading(false);
      return res;
    } catch (error) {
      handleError(error as Error, options as Options);
    }
  };

  return {
    data: storeData,
    loading,
    create,
    update,
    deleteData,
    getSingleData,
    getListData,
    useCollectionData: (args: Omit<ApiColArgs<DataType, ArgsType>, 'apiDataKey'>) =>
      useCollectionData<DataType, ArgsType>({ ...args, apiDataKey } as ApiColArgs<
        DataType,
        ArgsType
      >),
    useCollectionDataOnce: (args: Omit<ApiColArgs<DataType, ArgsType>, 'apiDataKey'>) =>
      useCollectionDataOnce({ apiDataKey, ...args } as ApiColArgs<DataType, ArgsType>),
    useDocumentData: (args: Omit<ApiDocArgs<DataType, ArgsType>, 'apiDataKey'>) =>
      useDocumentData({ apiDataKey, ...args } as ApiDocArgs<DataType, ArgsType>),
    useDocumentDataOnce: (args: Omit<ApiDocArgs<DataType, ArgsType>, 'apiDataKey'>) =>
      useDocumentDataOnce({ apiDataKey, ...args } as ApiDocArgs<DataType, ArgsType>),
  };
};
